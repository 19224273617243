import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size } from 'lodash';
import { connect } from 'react-redux';
import {
  getAllDepartmentList,
} from 'redux/selectors';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function ProductivityBasicSalaryInfo({
  allDepartmentList
}) {
  const [ displayReportLoader, toggleReportLoader ] = useState(false);
  const [ reportData, setReportData ] = useState([
    {
        type: 'CS. Cebu',
        ratio: 10
    },
    {
        type: 'CS. Syd',
        ratio: 25
    },
    {
        type: 'IT',
        ratio: 32
    },
    {
        type: 'Sales',
        ratio: 46
    },
   
  ]);

  const mappedCollections = allDepartmentList.map(val => ({
    type: val.short_department_name,
    ratio: 15.5,
  }));

 

  const config = {
    data: reportData,
    height:300,
    xField: 'type',
    yField: 'ratio',
    // seriesField: 'year',
    label: {
      position: 'middle',
      content:''
    },
    columnStyle: {
      cursor: 'pointer'
    },
    color:'#4BA7B3',
    legend: false,
    yAxis: {
      max: isNaN(max(map(reportData, 'ratio'))) ? 100 : max(map(reportData, 'ratio')) + 15,
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      }
    },
    xAxis: {
      label: {
        formatter: (value) => value,
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
    },
    // theme: {
    //   colors10: [
    //     // '#0F1C73',
    //     // '#1A449F',
    //     '#2B67BA',
    //     '#3C88C0',
    //     '#4BA7B3',
    //     '#5AC39B',
    //     '#6ADD7F',
    //     '#A8DD6A',
    //     '#DDDD6A',
    //   ],
    // },
  };
  return (
    <>      
      <StyledTitle>
          Basic Salary: Total Staff to Cost Ratio
      </StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayReportLoader}>
          <Column
            {...config}
             />
        </Spin>
      </StyledChartContainer>
    </>
  );
}

const mapStateToProps = state => ({
  allDepartmentList: getAllDepartmentList(state),
});


export default connect(mapStateToProps)(ProductivityBasicSalaryInfo)