import React, { useEffect, useState } from "react";
import moment from "moment";
import { Row, Col, Badge } from "antd";
import styled from "styled-components";
import {
  Calendar as AntCalendar,
  Spin,
  Select,
  Checkbox,
  Modal,
  message,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  addMonthinDate,
  dateInDetail,
  getFirstDate,
  getKenyanDateTime,
  getLastDate,
  getMySqlDate,
  isDateBetweenTwoDates,
  momentDate,
} from "utils/Date";
import COLORS from "common/Colors";
import UpcomingHolidays from "organisms/UpcomingHolidays";
import StaffLeaveIndicatorInCalendar from "molecules/StaffLeaveIndicatorInCalendar";
import LeaveForm from "organisms/LeaveForm";
import { CALL_API } from "common/API";
// Redux
import { connect } from "react-redux";
import {
  getAllDepartmentList,
  getUserRoleId,
  getSelectedDepartmentId,
  getUserInfo,
} from "redux/selectors";
import {
  concat,
  debounce,
  filter,
  find,
  first,
  get,
  includes,
  map,
} from "lodash";
import { STATUS_CODE } from "common/Constants";
import LeaveList from "organisms/LeaveList";
import { DEVICE } from "common/Device";

const StyledTitle = styled.div`
  font-size: larger;
  font-weight: bold;
  padding: 10px 0px 20px 0px;
`;

const StyledAntCalendarSmall = styled(AntCalendar)`
  background: transparent !important;
  .ant-picker-calendar-header {
    display: none;
  }
  .ant-picker-calendar {
    line-height: 1rem;
  }
  .ant-picker-body {
    width: 50px;
    padding-top: 0px !important;
  }
  .ant-picker-content {
    height: 200px;
    th {
      width: 30px;
      line-height: 40px;
      font-weight: bold;
    }
    td {
      padding: 0px;
    }
    tr {
      height: 15px;
      line-height: 15px;
    }
    .ant-picker-calendar-date-content {
      display: none;
    }
  }
  .ant-picker-panel {
    background: transparent !important;
  }
  td,
  th {
    font-size: 0.8rem;
    width: 20px;
  }
  .ant-picker-date-panel {
    width: 95%;
  }
  .ant-picker-cell::before {
    height: 20px;
  }
`;

const StyledAntCalendarFull = styled(AntCalendar)`
  background: transparent !important;
  .ant-picker-calendar-header {
    display: none;
  }
  .ant-picker-panel {
    background: transparent !important;
  }
  .ant-picker-cell,
  th {
    border: solid 1px #9c9c9c;
    text-align: left;
  }
  th {
    width: 100px;
    padding: 10px !important;
    text-align: center;
    font-weight: bold;
    border-bottom: none;
  }
  th:nth-child(1):after {
    content: "UN";
  }
  th:nth-child(2):after {
    content: "ON";
  }
  th:nth-child(3):after {
    content: "UE";
  }
  th:nth-child(4):after {
    content: "ED";
  }
  th:nth-child(5):after {
    content: "HU";
  }
  th:nth-child(6):after {
    content: "RI";
  }
  th:nth-child(7):after {
    content: "AT";
  }
  .ant-picker-cell-in-view {
    color: ${COLORS.BLACK};
  }
  tbody tr:nth-child(1) td {
    border-top: none !important;
  }

  @media ${DEVICE.mobileL} {
    th {
      font-size: 10px;
    }
  }
`;

const SmallCalendar = styled(Row)`
  padding-top: 10px;
`;

const smallCalendarIconStyle = {
  padding: "0px 10px",
  fontSize: "1rem",
  userSelect: "none",
};

const StyledPreviousIcon = styled(LeftOutlined)`
  ${smallCalendarIconStyle}
`;

const StyledNextIcon = styled(RightOutlined)`
  ${smallCalendarIconStyle}
`;

const StyledUpcomingHolidaysContainer = styled.div`
  padding: 20px 0px;
`;

const StyledLeaveCount = styled.div`
  height: 15px;
  width: 15px;
  margin: 10px;
  ${({ color }) => color && `background: ${color}`};
`;

const StyledLeaveText = styled.div`
  padding: 5px;
`;

const StyledDateCell = styled.div`
  padding: 15px 5px 5px 5px;
  .day {
    padding: 6px 5px;
    border-radius: 50px;
    font-size: 0.8rem;
    font-weight: bold;
  }
  .has-pending-leaves {
    background: ${COLORS.PENDING_LEAVE};
    color: ${COLORS.WHITE};
  }
  .hr-leave-container {
    text-align: center;
    padding-top: 50px;

    .ant-badge-count {
      min-width: 30px !important;
      height: 30px !important;
      line-height: 30px !important;
      border-radius: 50px;
      font-size: 14px;
      box-shadow: 0px 1px 5px #6f6f6f;
      font-weight: bold;
    }
    @media (max-width: 935px) {
      .ant-badge-count {
        min-width: 15px !important;
        height: 15px !important;
        line-height: 15px !important;
      }
    }
    @media (max-width: 762px) {
      .ant-badge-count {
        min-width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
      }
    }
    @media (max-width: 600px) {
      .ant-badge-count {
        min-width: 15px !important;
        height: 15px !important;
        line-height: 15px !important;
      }
    }
    .hr-has-pending-leaves {
      margin-right: 2px;
      .ant-badge-count {
        background: ${COLORS.PENDING_LEAVE};
      }
    }
  }
  .hr-has-approved-leaves {
    margin-left: 3px;
    .ant-badge-count {
      background: ${COLORS.APPROVED_LEAVE};
    }
  }
  @media ${DEVICE.mobileL} {
    padding: 0px;
    height: 100px;
    .hr-leave-container {
      padding-top: 0px;
    }
  }
  .has-approved-leaves {
    background: ${COLORS.APPROVED_LEAVE};
    color: ${COLORS.WHITE};
  }
  .ant-picker-calendar-date-content {
    height: 46px;
    margin-top: 5px;
  }
`;

const StyleDepartmentDropdown = styled(Select)`
  width: 200px;
  text-align: left;
  @media ${DEVICE.tablet} {
    width: 100% !important;
    margin-bottom: 10px;
  }
`;

const StyledHolidayBadge = styled(Badge)`
  margin-top: 5px;
  background: red;
  padding: 5px;
  color: white;
  border-radius: 50%;
  font-size: 9px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2px;
  left: 14px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 5px;
  padding-left: 10px;
  padding-top: 10px;
`;

const StyledLeftPanel = styled(Col)`
  @media ${DEVICE.mobileS} {
    padding-bottom: 20px;
  }
`;

function Calendar({
  userRoleId,
  selectedDepartmentId,
  allDepartmentList,
  userInfo,
}) {
  const [miniCalendarCurrentDate, setMiniCalendarCurrentDate] = useState(
    getKenyanDateTime()
  );
  const [departmentSelectedByHR, setDepartmentSelectedByHR] = useState("");
  const [leaveListFiterForHR, setLeaveListFiterForHR] = useState({});
  const [leaveList, setLeaveList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [myTeamLeaveList, setMyTeamLeaveList] = useState([]);
  const [displayLoader, toggleDisplayLoader] = useState(false);
  const [displayLeaveModal, toggleLeaveModal] = useState(false);
  const [isLeaveListModalVisible, toggleLeaveListModalVisible] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState({});
  const [showMyTeamLeaves, toggleMyTeamLeave] = useState(false);
  moment.updateLocale("en", {
    weekdaysMin: ["S", "M", "T", "W", "T", "F", "S"],
  });

  useEffect(() => {
    if (
      selectedDepartmentId &&
      userRoleId &&
      ((userRoleId === 1 && departmentSelectedByHR) ||
        userRoleId !== 1 ||
        userRoleId !== 6 ||
        (userRoleId === 6 && departmentSelectedByHR))
    ) {
      debounceGetLeaveList();
      debounceGetHolidayList();
    }
  }, []);

  useEffect(() => {
    if (userRoleId !== 1 || userRoleId !== 6) {
      debounceGetLeaveList();
    }
  }, [selectedDepartmentId, userRoleId]);

  useEffect(() => {
    debounceGetLeaveList();
  }, [miniCalendarCurrentDate, showMyTeamLeaves]);

  useEffect(() => {
    debounceGetHolidayList();
  }, [miniCalendarCurrentDate]);

  useEffect(() => {
    if (userRoleId === 1 || userRoleId === 6) {
      debounceGetLeaveList();
    }
  }, [departmentSelectedByHR]);

  const getLeaveList = async () => {
    setLeaveList([]);
    setMyTeamLeaveList([]);
    toggleDisplayLoader(true);
    const {
      code,
      leaves,
      teamLeaves = [],
    } = await CALL_API("leave-list", "post", {
      status_id: "4,5",
      department_id:
        userRoleId === 1
          ? [departmentSelectedByHR]
          : userRoleId === 4
          ? [get(userInfo, "staff_department_id", "")]
          : selectedDepartmentId,
      selected_role_id: userRoleId,
      start_date: getFirstDate(miniCalendarCurrentDate),
      end_date: getLastDate(miniCalendarCurrentDate),
      show_my_team_leave: showMyTeamLeaves ? true : false,
    });
    toggleDisplayLoader(false);
    if (code === STATUS_CODE.SUCCESS) {
      let leaveList = [];
      leaves.map((list) => {
        // first parameter is needed
        Object.entries(list).forEach(([key, value]) => {
          leaveList = concat(leaveList, value);
        });
      });
      let teamLeave = [];
      teamLeaves.map((list) => {
         // first parameter is needed
        Object.entries(list).forEach(([key, value]) => {
          teamLeave = concat(teamLeave, value);
        });
      });
      setLeaveList(leaveList);
      setMyTeamLeaveList(teamLeave);
    }
  };

  const getHolidayList = async () => {
    const { code, holidays } = await CALL_API("holiday-list", "post", {
      start_date: getFirstDate(miniCalendarCurrentDate),
      end_date: getLastDate(miniCalendarCurrentDate),
    });
    if (code === STATUS_CODE.SUCCESS) {
      setHolidayList(holidays);
    }
  };

  const debounceGetLeaveList = debounce(getLeaveList, 100);
  const debounceGetHolidayList = debounce(getHolidayList, 100);

  const previousMonth = () =>
    setMiniCalendarCurrentDate(
      momentDate(addMonthinDate(miniCalendarCurrentDate, "-1"))
    );

  const nextMonth = () =>
    setMiniCalendarCurrentDate(
      momentDate(addMonthinDate(miniCalendarCurrentDate, "1"))
    );

  const addLeave = (event, cellDate) => {
    if (
      userRoleId === 4 &&
      event.target.className === "ant-picker-calendar-date-content"
    ) {
      const isPastDate =
        moment(getMySqlDate(cellDate)) < moment(getMySqlDate("2022-08-01"));
      if (!isPastDate) {
        toggleLeaveModal(true);
        setLeaveDetails({
          start_date: cellDate,
          end_date: cellDate,
        });
      }
    }
  };

  const dateFullCellRender = (cellDate) => {
    const isCurrentMonth = true;
    const { date: day, day: Days } = dateInDetail(cellDate);
    const leave = filter(
      leaveList,
      ({ start_date, new_start_date, new_end_date, end_date, status_id }) =>
        includes([4, 5], status_id) &&
        isDateBetweenTwoDates(
          cellDate,
          new_start_date === null ? start_date : new_start_date,
          new_end_date === null ? end_date : new_end_date
        ),
      {}
    );
    const teamLeave =
      userRoleId === 4 &&
      filter(
        myTeamLeaveList,
        ({ start_date, end_date, status_id }) =>
          includes([4, 5], status_id) &&
          isDateBetweenTwoDates(cellDate, start_date, end_date),
        {}
      );
    const leaveDetails = first(leave);
    const statusId = userRoleId === 4 ? get(leaveDetails, "status_id", "") : "";
    const havingcancelrequest =
      userRoleId === 4 ? get(leaveDetails, "having_cancel_request", "") : "";
    const leaveType =
      isCurrentMonth && statusId === 4 && (Days != 'Sunday' || includes([5,6],leaveDetails.leave_category_id))
        ? "has-pending-leaves" 
        : havingcancelrequest === 1 && (Days != 'Sunday' || includes([5,6],leaveDetails.leave_category_id))
        ? "has-pending-leaves" 
        : statusId === 5 && (Days != 'Sunday' || includes([5,6],leaveDetails.leave_category_id))
        ? "has-approved-leaves"
        : "";
    const isHoliday = find(holidayList, ({ start_date, end_date }) =>
      isDateBetweenTwoDates(cellDate, start_date, end_date)
    );
    const leaveColor = get(isHoliday, "color", "");
    const hrPendingLeaves = filter(
      leaveList,
      ({
        start_date,
        end_date,
        status_id,
        having_cancel_request,
        new_start_date,
        new_end_date,
      }) =>
        (status_id === 4 &&
          isDateBetweenTwoDates(cellDate, start_date, end_date)) ||
        (status_id === 5 &&
          having_cancel_request === 1 &&
          isDateBetweenTwoDates(cellDate, new_start_date, new_end_date)),
      {}
    ).length;
    const hrApprovedLeaves = filter(
      leaveList,
      ({ start_date, end_date, status_id, having_cancel_request }) =>
        status_id === 5 &&
        having_cancel_request === 0 &&
        isDateBetweenTwoDates(cellDate, start_date, end_date),
      {}
    ).length;
    return (
      <StyledDateCell onClick={(event) => addLeave(event, cellDate)}>
        <span className={`day ${leaveType}`}>
          {isCurrentMonth &&  (
            <>
              {leaveColor && (
                <StyledHolidayBadge
                  status="success"
                  size="small"
                  color={leaveColor}
                >
                  H
                </StyledHolidayBadge>
              )}
              {leaveType && (
                <StaffLeaveIndicatorInCalendar
                  icon="team"
                  leaveDetails={leaveDetails}
                  isStaff={userRoleId === 4}
                  day={day}
                  openEditLeaveModal={(details) => {
                    cancelLeaveRequest(details);
                  }}
                  EditLeaveModal={(details) => {
                    cancelLeave(details);
                  }}
                >
                  {day}
                </StaffLeaveIndicatorInCalendar>
              )}
              {!leaveType && day}
            </>
          )}
          {!isCurrentMonth && day}
        </span>
        <div className="ant-picker-calendar-date-content">
          {includes([2, 3, 4, 6], userRoleId) &&
            (leave.length > 0 || teamLeave.length > 0) &&
            map(userRoleId === 4 ? teamLeave : leave, (leaveDetails) => (
              <StaffLeaveIndicatorInCalendar
                key={leaveDetails.user_leave_id}
                icon="team"
                leaveDetails={leaveDetails}
                isStaff={false}
                reloadList={getLeaveList}
                openEditLeaveModal={() => {
                  setLeaveDetails(leaveDetails);
                  toggleLeaveModal(true);
                }}
                EditLeaveModal={() => {
                  setLeaveDetails(leaveDetails);
                  toggleLeaveModal(true);
                }}
                displayLeaveDetail={userRoleId !== 4}
                Days={Days}
              />
            ))}
            
          {userRoleId === 1 && (Days != 'Sunday' || includes([5,6],leaveDetails?.leave_category_id)) &&(
            <>
              <div className="hr-leave-container">
                <Badge
                  count={hrPendingLeaves}
                  className="hr-has-pending-leaves"
                  onClick={() => {
                    setLeaveListFiterForHR({
                      date: cellDate,
                      status_id: 4,
                    });
                    toggleLeaveListModalVisible(true);
                  }}
                />
                <Badge
                  count={hrApprovedLeaves}
                  className="hr-has-approved-leaves"
                  onClick={() => {
                    setLeaveListFiterForHR({
                      date: cellDate,
                      status_id: 5,
                    });
                    toggleLeaveListModalVisible(true);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </StyledDateCell>
    );
  };

  const cancelLeaveRequest = (details) => {
    const {
      user_leave_id,
      start_date: leave_start_date,
      end_date: leave_end_date,
      status_id,
    } = details;
    if (userRoleId === 4 && status_id !== 4) {
      Modal.confirm({
        icon: "",
        content: "Are you sure you want to cancel your leave application?",
        okText: "YES",
        cancelText: "No",
        onOk: () => {
          return new Promise(async (resolve) => {
            const { code } = await CALL_API(
              "approve-reject-cancel-leave",
              "post",
              {
                user_leave_id,
                status_id: 9,
                is_cancel_request: false,
                start_date: getMySqlDate(leave_start_date),
                end_date: getMySqlDate(leave_end_date),
              }
            );
            if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
              message.error(`Oops!! something went wrong.`);
            } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
              message.error(`Invalid payload. Please enter correct data.`);
            } else if (code === STATUS_CODE.RECORD_EXIST) {
              message.error(`Leave aleady exist.`);
            } else if (code === STATUS_CODE.SUCCESS) {
              message.success(`Your leave application has been cancelled.`);
              getLeaveList();
            }
            resolve();
          }).catch((err) => console.log("[cancelLeaveRequest] Error -->", err));
        },
      });
    } else {
      setLeaveDetails(details);
      toggleLeaveModal(true);
    }
  };

  const cancelLeave = (details) => {
    const {
      user_leave_id,
      start_date: leave_start_date,
      end_date: leave_end_date,
      status_id,
    } = details;
    if (userRoleId === 4 && status_id === 4) {
      Modal.confirm({
        icon: "",
        content: "Are you sure you want to cancel your leave application?",
        okText: "YES",
        cancelText: "No",
        onOk: () => {
          return new Promise(async (resolve) => {
            const { code } = await CALL_API(
              "approve-reject-cancel-leave",
              "post",
              {
                user_leave_id,
                status_id: 9,
                is_cancel_request: false,
                start_date: getMySqlDate(leave_start_date),
                end_date: getMySqlDate(leave_end_date),
              }
            );
            if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
              message.error(`Oops!! something went wrong.`);
            } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
              message.error(`Invalid payload. Please enter correct data.`);
            } else if (code === STATUS_CODE.RECORD_EXIST) {
              message.error(`Leave aleady exist.`);
            } else if (code === STATUS_CODE.SUCCESS) {
              message.success(`Your leave application has been cancelled.`);
              getLeaveList();
            }
            resolve();
          }).catch((err) => console.log("[cancelLeave] Error-->", err));
        },
      });
    } else {
      setLeaveDetails(details);
      toggleLeaveModal(true);
    }
  };

  const { monthName, year } = dateInDetail(miniCalendarCurrentDate);
  const iconStyle = {
    fontSize: "1.2rem"
  }

  return (
    <Row gutter={20}>
      <StyledLeftPanel xs={24} sm={15} md={10} lg={10} xl={5}>
        <StyledTitle>
          {userRoleId === 4 && "My "}
          Calendar
        </StyledTitle>
        <SmallCalendar>
          <Col span={14} className="font-bold">
            {monthName} {year}
          </Col>
          <Col span={10} className="align-right">
            <StyledPreviousIcon onClick={previousMonth} />
            <StyledNextIcon onClick={nextMonth} />
          </Col>
        </SmallCalendar>
        <StyledAntCalendarSmall
          fullscreen={false}
          value={momentDate(miniCalendarCurrentDate)}
        />
        <StyledUpcomingHolidaysContainer>
          <UpcomingHolidays holidayList={holidayList}></UpcomingHolidays>
        </StyledUpcomingHolidaysContainer>

        <Row gutter={20}>
          <Col span={4}>
            <StyledLeaveCount color={COLORS.PENDING_LEAVE} />
          </Col>
          <Col span={20}>
            <StyledLeaveText>
              Pending Leaves{includes([1, 4], userRoleId) ? "" : " count"}
            </StyledLeaveText>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={4}>
            <StyledLeaveCount color={COLORS.APPROVED_LEAVE} />
          </Col>
          <Col span={20}>
            <StyledLeaveText>
              Approved Leaves{includes([1, 4], userRoleId) ? "" : " count"}
            </StyledLeaveText>
          </Col>
        </Row>

        {userRoleId === 4 && (
          <StyledCheckbox
            onChange={() => toggleMyTeamLeave(!showMyTeamLeaves)}
            checked={showMyTeamLeaves}
          >
            My team
          </StyledCheckbox>
        )}
      </StyledLeftPanel>
      <Col xs={24} sm={24} md={19}>
        <Row>
          <Col md={12} sm={24} xs={24}>
            <StyledTitle>
              <StyledPreviousIcon
                onClick={previousMonth}
                style={iconStyle}
              />
              {monthName} {year}
              <StyledNextIcon
                onClick={nextMonth}
                style={iconStyle}
              />
            </StyledTitle>
          </Col>
          <Col md={10} sm={24} xs={24} className="align-right">
            {userRoleId === 1 && (
              <StyleDepartmentDropdown
                placeholder="Select Department"
                options={concat(
                  {
                    department_id: "",
                    department_name: "All",
                  },
                  allDepartmentList
                )}
                onChange={(id) => setDepartmentSelectedByHR(id)}
                value={departmentSelectedByHR}
                fieldNames={{
                  label: "department_name",
                  value: "department_id",
                }}
              />
            )}
          </Col>
        </Row>
        <Spin spinning={displayLoader}>
          <StyledAntCalendarFull
            value={momentDate(miniCalendarCurrentDate)}
            fullscreen={true}
            dateFullCellRender={dateFullCellRender}
          />
        </Spin>
      </Col>
      <LeaveForm
        isModalVisible={displayLeaveModal}
        closeModal={(isReloadLeave) => {
          toggleLeaveModal(false);
          if (isReloadLeave) getLeaveList();
        }}
        leaveDetails={leaveDetails}
      />
      <LeaveList
        isModalVisible={isLeaveListModalVisible}
        closeModal={() => toggleLeaveListModalVisible(false)}
        date={get(leaveListFiterForHR, "date", "")}
        status_id={get(leaveListFiterForHR, "status_id", "")}
        department_id={departmentSelectedByHR}
      />
    </Row>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(Calendar);
