import React from 'react';
import { Bar } from '@ant-design/plots';
import styled from '@emotion/styled';


const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function DemographicsAgeDistribution({ report }) {
 
  const config = {
    data: report,
    height:300,
    isStack: true,
    xField: 'count',
    yField: 'age_group',
    seriesField: 'sex',
    label: {
      position: 'middle',
      content:''
    },
    columnStyle: {
      cursor: 'pointer'
    },
    legend: {
      layout: 'horizontal',
      position: 'top-right'
  },
  xAxis: {
    grid: {
      line: {
        style: {
          opacity: 0
        }
      }
    }
  },
  };
  return (
    <>      
      <StyledTitle>
         Age Distribution
      </StyledTitle>
      <StyledChartContainer>
         <Bar
            {...config}
          />
      </StyledChartContainer>
    </>
  );
}


export default DemographicsAgeDistribution