import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Spin, Select } from 'antd';
import styled from '@emotion/styled';
import LeaveDonutChart from './LeaveDonutChart';
import { LEAVE_ICON, LEAVE_INFO } from 'common/Constants';
import COLORS from 'common/Colors';
import { CALL_API } from 'common/API';

// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getAllDepartmentList,
  getLeaveCategoryList,
} from 'redux/selectors';
import { find, get, includes, map, reduce } from 'lodash';
import DepartmentStaffLeaveBalance from './DepartmentStaffLeaveBalance';
import { dateInDetail, getKenyanDateTime } from 'utils/Date';

const StyledModal = styled(Modal)`
   width: 700px !important;
  .ant-modal-body {
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
  }
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 10px;
`;

const StyledDepartmentTitle = styled.div`
  padding-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledSelect = styled(Select)`
  padding-bottom: 20px;
`;

function DepartmentLeaveBalance({
  displayModal,
  toggleModal,
  title,
  leaveTypeId,
  allDepartmentList,
  leaveCategoryList
}) {
  const [ selectedDepartmentId, setSelectedDepartmentId ] = useState('');
  const [ staffDepartmentLeaveModal, toggleStaffDepartmentLeaveModal ] = useState(false);
  const [ leaveName, setLeaveName ] = useState('');
  const [ departmentLeaves, setDepartmentLeaves ] = useState([]);
  const [ filteredDepartments, setFilteredDepartments ] = useState([]);
  const leaveDataArray = [
    { type: LEAVE_INFO.AVAILABLE, value: 0, color: get(COLORS, `LEAVE.${leaveTypeId}.AVAILABLE`, '') },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: get(COLORS, `LEAVE.${leaveTypeId}.CONSUMED`, '') },
  ];
  const [ displayLeaveReportLoader, toggleLeaveReportLoader ] = useState(false);
  const [ APIResponse, setAPIResponse ] = useState([]);

  const openDetailWindow = (departmentId) => {
    setSelectedDepartmentId(departmentId);
    toggleStaffDepartmentLeaveModal(true);
  }

  useEffect(() => {
    if (displayModal) {
      setLeaveName(get(find(leaveCategoryList, { leave_category_id: leaveTypeId }), 'leave_category_name', ''));
      const departmentList = [];
      map(allDepartmentList, ({ department_id, department_name }) => {
        departmentList.push({
          department_id,
          department_name,
          leaveData: leaveDataArray
        })
      });
      setDepartmentLeaves(departmentList);
      getReportData();
      setAPIResponse({
        available: [],
        consumed: []
      });
    }
  }, [ displayModal ]);

  useEffect(() => {
    const data = reduce(allDepartmentList, (result, department) => {
      if (filteredDepartments.length === 0 || includes(filteredDepartments, department.department_id)) {
        let dupeleaveDataArray = JSON.parse(JSON.stringify(leaveDataArray));
        const total_leaves = get(find(APIResponse.available, { 'department_id': department.department_id }), 'total_leaves', 0);
        const leaves_consumed = get(find(APIResponse.consumed, { 'department_id': department.department_id }), 'leaves_consumed', 0);
        dupeleaveDataArray[0].value = total_leaves - leaves_consumed;
        dupeleaveDataArray[1].value = leaves_consumed;
        result.push({
          department_id: department.department_id,
          department_name: department.department_name,
          leaveData: dupeleaveDataArray
        });
      }
      return result;
    }, [])
    setDepartmentLeaves(data);
  }, [ filteredDepartments, APIResponse ]);

  const getReportData = async () => {
    toggleLeaveReportLoader(true);
    const {
      available,
      consumed,
    } = await CALL_API('leave-service', 'post', {
      method: 'departmentWiseOverallTotalLeavesAvailableAndConsumed', 
      parameters: {
        leave_type: leaveTypeId,
        year: dateInDetail(getKenyanDateTime()).year
      },
    });
    setAPIResponse({
      available,
      consumed
    });
    // const data = reduce(allDepartmentList, (result, department) => {
    //   let dupeleaveDataArray = JSON.parse(JSON.stringify(leaveDataArray));
    //   const total_leaves = get(find(available, { 'department_id': department.department_id }), 'total_leaves', 0);
    //   const leaves_consumed = get(find(consumed, { 'department_id': department.department_id }), 'leaves_consumed', 0);
    //   dupeleaveDataArray[0].value = total_leaves - leaves_consumed;
    //   dupeleaveDataArray[1].value = leaves_consumed;
    //   result.push({
    //     department_id: department.department_id,
    //     department_name: department.department_name,
    //     leaveData: dupeleaveDataArray
    //   });
    //   return result;
    // }, [])
    // setDepartmentLeaves(data);
    toggleLeaveReportLoader(false);
  }

  return (
    <>
      <StyledModal
        title={title}
        visible={displayModal}
        onCancel={toggleModal}
        footer={null}
      >
        <Spin spinning={displayLeaveReportLoader}>
          <Row gutter={16}>
            <Col span={24}>
              <StyledSelect
                mode="multiple"
                showSearch
                style={{
                  width: '100%',
                }}
                placeholder='Search Departments'
                name='department'
                optionFilterProp='department_name'
                filterOption={(input, option) => option.department_name.toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) => {
                  optionA.department_name.toLowerCase().localeCompare(optionB.department_name.toLowerCase())
                }}
                options={allDepartmentList}
                fieldNames={{
                  label: 'department_name',
                  value: 'department_id'
                }}
                onChange={newValue => setFilteredDepartments(newValue)}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            {
              map(departmentLeaves, ({ department_id, department_name, leaveData }) => (
                <StyledCol md={12} sm={24} xs={24} key={department_id}  onClick={() => openDetailWindow(department_id)}>
                  <StyledDepartmentTitle>
                    <b>Department: </b>{department_name}
                  </StyledDepartmentTitle>
                  <LeaveDonutChart iconName={LEAVE_ICON[leaveTypeId]} title={leaveName} data={leaveData} />
                </StyledCol>
              ))
            }
          </Row>
        </Spin>
      </StyledModal>
      <DepartmentStaffLeaveBalance
        displayModal={staffDepartmentLeaveModal}
        toggleModal={() => toggleStaffDepartmentLeaveModal(false)}
        leaveTypeId={leaveTypeId}
        selectedDepartmentId={[selectedDepartmentId]}
        leaveName={leaveName}
      />
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  leaveCategoryList: getLeaveCategoryList(state),
  allDepartmentList: getAllDepartmentList(state),
});

export default connect(mapStateToProps, null)(DepartmentLeaveBalance);
