import React, { useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import LeaveDonutChart from "./LeaveDonutChart";
import styled from "@emotion/styled";
import UserLeaveDonut from "../organisms/UserLeaveDonut";
import { LEAVE_ICON, LEAVE_INFO } from "common/Constants";
import { CALL_API } from "common/API";
import COLORS from "common/Colors";
import { get } from "lodash";
import { dateInDetail, getKenyanDateTime } from "utils/Date";
import { getUserRoleId } from "redux/selectors";
import { connect } from "react-redux";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

function UserLeaveReport({ userRoleId, leaveCategoryList }) {
  const [loading, setLoading] = useState(false);
  const [LastYearLeave, setLastYearLeave] = useState("");
  const [annualLeaveData, setAnnualLeaveData] = useState([
    {
      type: LEAVE_INFO.AVAILABLE,
      value: 0,
      color: COLORS.LEAVE.ANNUAL_AVAILABLE,
    },
    {
      type: LEAVE_INFO.CONSUMED,
      value: 0,
      color: COLORS.LEAVE.ANNUAL_CONSUMED,
    },
  ]);
  const [sickLeaveData, setSickLeaveData] = useState([
    {
      type: LEAVE_INFO.AVAILABLE,
      value: 0,
      color: COLORS.LEAVE.SICK_AVAILABLE,
    },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: COLORS.LEAVE.SICK_CONSUMED },
  ]);

  const [restdadsData, setrestDaysData] = useState([
    {
      type: LEAVE_INFO.AVAILABLE,
      value: 0,
      color: COLORS.LEAVE.SICK_AVAILABLE,
    },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: COLORS.LEAVE.SICK_CONSUMED },
  ]);
  const getCheckinData = async () => {
    setLoading(true);
    const { annual, sick, sickLeaveHalfpayHalfpay, restdays } = await CALL_API(
      "employeeleave-detail",
      "post",
      {
        year: dateInDetail(getKenyanDateTime()).year,
      }
    );
    let dupeLeaveData = Array.from(annualLeaveData);
    dupeLeaveData[1].value =
      get(annual, "consumed", 0) != null ? get(annual, "consumed", 0) : 0;
    dupeLeaveData[0].value =
      get(annual, "available", 0) != null
        ? get(annual, "available", 0) - dupeLeaveData[1].value
        : 0;
    setLastYearLeave(get(annual, "lastYearLeaveBalance", ""));
    setAnnualLeaveData(dupeLeaveData);

    dupeLeaveData = Array.from(sickLeaveData);
    dupeLeaveData[0].value = get(sick, "available", 0);
    dupeLeaveData[1].value = get(sick, "consumed", 0);
    setSickLeaveData(dupeLeaveData);
    setLoading(false);

    dupeLeaveData = Array.from(restdadsData);
    dupeLeaveData[0].value =
      get(restdays, "available", 0) != null ? get(restdays, "consumed", 0) : 0;
    dupeLeaveData[1].value =
      get(restdays, "consumed", 0) != null
        ? get(restdays, "available", 0) - dupeLeaveData[1].value
        : 0;
    setrestDaysData(dupeLeaveData);
    setLoading(false);
  };
  useEffect(() => {
    getCheckinData();
  }, []);

  return (
    <>
      <StyledTitle>LEAVE REPORT</StyledTitle>
      <Row gutter={16}>
        <Col md={24} sm={24} xs={24} xl={24}>
          <Spin spinning={loading}>
            <UserLeaveDonut
              iconName={LEAVE_ICON[7]}
              title="ANNUAL LEAVE"
              data={annualLeaveData}
              LastYearLeaveData={LastYearLeave}
            />
          </Spin>
        </Col>
        {leaveCategoryList.find(
          (category) => category.leave_category_id == 3
        ) && (
          <Col md={24} sm={24} xs={24} xl={24}>
            <Spin spinning={loading}>
              <LeaveDonutChart
                iconName={LEAVE_ICON[1]}
                title="REST DAYS"
                data={restdadsData}
              />
            </Spin>
          </Col>
        )}
      </Row>
    </>
  );
}
const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, null)(UserLeaveReport);
